import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

import BlogList from '../templates/blog-list';

export default ({ pageContext, location }) => {

  const { id, title, articles } = pageContext.node;
  const { crumbs } = pageContext.breadcrumb;
    
  const customCrumbLabel = location.pathname.toLowerCase().replace(/\/.*\//, '');


  console.log('pageContext: ', pageContext);
  
  // const createMarkup = () => ({ __html: html });

  const createTiles = arr => arr.map( (tile) => <li key={tile.id}><Link to={`${title}/${tile.handle}`}><p>{tile.title}</p></Link></li> )

  // const checkWhatTilesToMake = () => {
    
    
  //   if (blogPosts === null && blogAuthors) {
  //     return createAuthorTiles(blogAuthors);
  //   } else if (blogPosts && blogAuthors == null) {
  //     return  createBlogTiles(blogPosts);
  //   } else {
  //     return  null;
  //   }
  // }

  // const createBlogTiles = posts => posts.map( (post, i) => {
  //   console.log(post);
    
  //     return (
  //       <div key={i} style={{ width: '50%' }}>
  //         {/* <Link to={post.handle}>
  //             <img src={post.headerImage.file.url} alt={post.headerImage.file.fileName} />
  //         </Link> */}
  //         <Link to={post.handle} style={{ display: 'block' }}>
  //             {post.blogTitle}
  //         </Link>
  //       </div>  
  //     );
  // } );

  // const createAuthorTiles = authors => authors.map( (author, i) => {
  //   return (
  //     <div key={i} style={{ width: '50%' }}>
  //         <Link to={author.handle} style={{ display: 'block' }}>
  //             {author.handle}
  //         </Link>
  //       </div>  
  //   );
  // } );

  return(
    <Layout>
      {/* <SEO title={title} description={description} />  */}
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" - "
        crumbLabel={customCrumbLabel}
      />
      <section>
        <ul>
         {articles ? createTiles(articles) : null}
        </ul>
      </section>
    </Layout>
    
  )
}
