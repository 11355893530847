import React from 'react';
import Link from 'gatsby-link';
import Layout from '../components/layout';
import Img from 'gatsby-image';
import SEO from '../components/seo';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

import BreadcrumbsWrapper from '../components/reusable/BreadcrumbsWrapper';

import './../components/assets/css/blog-list.scss';

export default ({ pageContext, location }) => {

  console.log('pageContext: ', pageContext)
      
  const NavLink = props => !props.test ? <Link to={`${props.prefix}/${props.url}`}>{props.text}</Link> : <span>{props.text}</span>;

  const { id, title, handle, group, index, first, last, pageCount, breadcrumb: { crumbs }, pathPrefix } = pageContext;
  const customCrumbLabel = location.pathname.toLowerCase().replace(/\/.*\//, '');

  const previousUrl = index - 1 === 1 ? '/' : `${index - 1}`;
  const nextUrl = `${index + 1}`;

   const paginatedBlogPosts = list => list.map( (post, i) => {    

    return (
      <li key={i}>
        <Link to={`${pathPrefix}/${post.handle}`}>
         <Img key={i} fluid={post.headerImage.fluid} />
          <h4>{post.blogTitle ? post.blogTitle : post.title}</h4>
        </Link>
      </li>
    );
  });

  const displayLinks = pageCount => pageCount > 1 ? (
    <React.Fragment>
      <div className="previousLink">
        <NavLink test={first} prefix={pathPrefix} url={previousUrl} text="Go to Previous Page" />
      </div>
      <div className="nextLink">
        <NavLink test={last} prefix={pathPrefix} url={nextUrl} text="Go to Next Page" />
      </div>
    </React.Fragment>
  ) : null;

   return (
    <Layout>
      {/* <SEO title={title} description={description} />  */}

      <BreadcrumbsWrapper crumbs={crumbs} crumbLabel={customCrumbLabel} />
      
      <section className="blog-list">
        <ul>{ paginatedBlogPosts(group) }</ul>

        { displayLinks(pageCount) }
        
      </section>
    </Layout>
  );
}
